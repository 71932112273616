import { Icon } from '@iconify/react';
import React, { useEffect } from 'react';
import { pageTitle } from '../../helper';
import Div from '../Div';
import PageHeading from '../PageHeading';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';
import ContactInfoWidget from '../Widget/ContactInfoWidget';
import Iframe from 'react-iframe';

export default function Vespers() {
  pageTitle('Vespers');
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Spacing lg="150" md="80" />
      <Iframe url="https://form.jotform.com/230664902435051"
        className="container"
        width="640px"
        height="700px"
        id=""
        display="block"
        position="relative"/>
    </>
  );
}
