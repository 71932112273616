import React, { useEffect } from 'react';
import Card from '../Card';
import FunFact from '../FunFact';
import Hero from '../Hero';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';
import Cta from '../Cta';
import LogoList from '../LogoList';
import MovingText from '../MovingText';
import PortfolioSlider from '../Slider/PortfolioSlider';
import PostSlider from '../Slider/PostSlider';
import TestimonialSlider from '../Slider/TestimonialSlider';
import TeamSlider from '../Slider/TeamSlider';
import VideoModal from '../VideoModal';
import TimelineSlider from '../Slider/TimelineSlider';
import { pageTitle } from '../../helper';
import Iframe from 'react-iframe';


export default function Home() {
  
  pageTitle('Home');

  // Hero Social Links
  const heroSocialLinks = [
    {
      name: 'Instagram',
      links: 'https://www.instagram.com/ecs_sau/',
    }
  ];

  const ctaLink = [
    {
      name: 'GoogleForm',
      links: 'https://docs.google.com/forms/d/e/1FAIpQLSc01Pe2a6sl2GAnGtNIZ02CBoIy5Jb1M1R6uVlJjaNqpO5Wzg/viewform?usp=sf_link',
    }
  ];

  // FunFact Data
  const funfaceData = [
    {
      title: 'Countries Represented',
      factNumber: '21',
    },
    {
      title: 'Club Members',
      factNumber: '40+',
    },
    // {
    //   title: '',
    //   factNumber: '',
    // },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  

  return (
    
    <>
      {/* Start Hero Section */}
      <Hero
        title="European <br/>Cultural Society"
        subtitle="We are a student-led society at Southern Adventist University, aiming to promote European culture and language."
        btnText="Sign up here!"
        btnLink={ctaLink}
        //"https://docs.google.com/forms/d/e/1FAIpQLSc5CU6lC8VYsihwO3G76ia4x1GRtxOpd6g5pHtwiYBOwJ9AUQ/viewform?usp=sf_link"
        scrollDownId="#events"
        socialLinksHeading="Follow Us"
        heroSocialLinks={heroSocialLinks}
        bgImageUrl="/images/EU1.jpg"
      />
      {/* End Hero Section */}

      {/* Start FunFact Section */}
      <div className="container">
        <FunFact
          variant="cs-type1"
          title="Our fun facts"
          subtitle=""
          data={funfaceData}
        />
      </div>
      {/* End FunFact Section */}

      {/* Start Portfolio Section */}
      <Spacing lg="150" md="50" />
      <Div id="events">
        <Div className="container">
          <SectionHeading
            title="Upcoming Events"
            subtitle=""
            variant="cs-style1 text-center"
          />
          <Spacing lg="90" md="45" />
        </Div>
        <PortfolioSlider />
        
      </Div>
      {/* End Portfolio Section */}

      {/* Start Service Section */}
      <Spacing lg="150" md="80" />
      <Div id="service">
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-4">
              <SectionHeading
                title="Some Countries Represented"
                subtitle=""
                // btnText=""
                // btnLink=""
              />
              <Spacing lg="90" md="45" />
            </Div>
            <Div className="col-xl-8">
              <Div className="row">
                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title="Austria"
                    //link=""
                    src="/images/AustiraF.png"
                    //alt=""
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title="France"
                    //link=""
                    src="/images/FranceF.png"
                    //alt=""
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title="Romania"
                    //link=""
                    src="/images/RomaniaF.png"
                    //alt="Service"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title="Germany"
                    //link=""
                    src="/images/GermanyF.png"
                    //alt=""
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      {/* End Service Section */}

      

      {/* Start Awards Section */}
      {/* <Spacing lg="150" md="80" />
      <Div className="cs-shape_wrap_2">
        <Div className="cs-shape_2">
          <Div />
        </Div>
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-4">
              <SectionHeading
                title="We get multiple awards"
                subtitle="Our Awards"
                variant="cs-style1"
              />
              <Spacing lg="90" md="45" />
            </Div>
            <Div className="col-xl-7 offset-xl-1">
              <TimelineSlider />
            </Div>
          </Div>
        </Div>
      </Div> */}
      {/* End Awards Section */}

      {/* Start Video Block Section */}
      <Spacing lg="130" md="70" />
      <Div className="container">
        <h2 className="cs-font_50 cs-m0 text-center cs-line_height_4">
          Tour Europe
        </h2>
        <Spacing lg="70" md="70" />
        <VideoModal
          videoSrc="https://www.youtube.com/watch?v=Pko9CVmfNdY"
          bgUrl="/images/EU2.jpg"
        />
      </Div>
      {/* End Video Block Section */}

      {/* Start Team Section */}
      

      {/* <Spacing lg="145" md="80" />
      <Div className="container">
        <SectionHeading
          title="Awesome team <br/>members"
          subtitle="Our Team"
          variant="cs-style1"
        />
        <Spacing lg="85" md="45" />
        <TeamSlider />
      </Div>
      <Spacing lg="150" md="80" /> */}



      {/* End Team Section */}

      {/* Start Testimonial Section */}
      {/* <TestimonialSlider /> */}
      {/* End Testimonial Section */}

      {/* Start Blog Section */}
      {/* <Spacing lg="150" md="80" />
      <Div className="cs-shape_wrap_4">
        <Div className="cs-shape_4"></Div>
        <Div className="cs-shape_4"></Div>
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-4">
              <SectionHeading
                title="Explore recent publication"
                subtitle="Our Blog"
                btnText="View More Blog"
                btnLink="/blog"
              />
              <Spacing lg="90" md="45" />
            </Div>
            <Div className="col-xl-7 offset-xl-1">
              <Div className="cs-half_of_full_width">
                <PostSlider />
              </Div>
            </Div>
          </Div>
        </Div>
      </Div> */}
      {/* End Blog Section */}

      {/* Start MovingText Section */}
      <Spacing lg="125" md="70" />
      <MovingText text="European Cultural Society" />
      <Spacing lg="105" md="70" />
      {/* End MovingText Section */}

      


  

      


      {/* Start CTA Section */}
      <Div className="container">
      
        <Cta
          title="Interested?"
          //btnText=" "
          
          
          bgSrc="/images/cta_bg.jpeg"
        />
      </Div>
      {/* End CTA Section */}
    </>
  );
}


