import React from 'react';
import parse from 'html-react-parser';
import './hero.scss';
import Button from '../Button';
import Div from '../Div';
import VerticalLinks from '../VerticalLinks';
import { useEffect, useState } from 'react';

export default function Hero({
  title,
  subtitle,
  btnText,
  btnLink,
  scrollDownId,
  socialLinksHeading,
  heroSocialLinks,
  bgImageUrl,
  desktopImage,
  mobileImage,

  

}) {

  desktopImage = "/images/EU1.jpg"
  mobileImage = "/images/pexeleuro12.jpg"

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const imageUrl = windowWidth >= 650 ? desktopImage : mobileImage;

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowWidth(window.innerWidth);
        };
        
        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
            console.log(windowWidth)
        }

    
  }, []);


  if (windowWidth >= 650) {
    bgImageUrl = desktopImage

  } else {
    bgImageUrl = mobileImage

  }




  return (
    <Div
      
      className="cs-hero cs-style1 cs-bg cs-fixed_bg cs-shape_wrap_1 "
      style={{ backgroundImage: `url(${bgImageUrl})`}} 
    >
      <Div className="cs-shape_1" />
      <Div className="cs-shape_1" />
      <Div className="cs-shape_1" />
      <Div className="container">
        <Div className="cs-hero_text">
          <h1 className="cs-hero_title">{parse(title)}</h1>
          <Div className="cs-hero_info">
            
            <Div>
            <a style={{color: "black", fontWeight: "bold", fontSize: "25px", }} href="https://docs.google.com/forms/d/e/1FAIpQLSc01Pe2a6sl2GAnGtNIZ02CBoIy5Jb1M1R6uVlJjaNqpO5Wzg/viewform?usp=sf_link">Click here to join the club!</a>
              
              {/* <Button btnLink={btnLink} btnText={btnText} /> */}
            </Div>
            
            <Div>
              <Div className="cs-hero_subtitle">{subtitle}</Div>
            </Div>
          </Div>
        </Div>
      </Div>
      <VerticalLinks data={heroSocialLinks} title={socialLinksHeading} />
      <a href={scrollDownId} className="cs-down_btn">
        .
      </a>
    </Div>
  );
}

