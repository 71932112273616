import React from 'react'
import parse from 'html-react-parser';
import './cta.scss'
import Button from '../Button';
import Div from '../Div';
import Spacing from '../Spacing';

export default function Cta({title, btnText, bttnLink, bgSrc, variant}) {
const btnLink = "https://docs.google.com/forms/d/e/1FAIpQLSc01Pe2a6sl2GAnGtNIZ02CBoIy5Jb1M1R6uVlJjaNqpO5Wzg/viewform?usp=sf_link"
  return (
    <Div className={`cs-cta cs-style1 cs-bg text-center cs-shape_wrap_1 cs-position_1 ${variant?variant:''}`} style={{backgroundImage: `url(${bgSrc})`}}>
      <Div className="cs-shape_1" />
      <Div className="cs-shape_1" />
      <Div className="cs-shape_1" />
      <Div className="cs-cta_in">
        <h2 className="cs-cta_title cs-semi_bold cs-m0">{parse(title)}</h2>
        {btnText && (
          <>
            <Spacing lg='70' md='30' />
            
            <Button 
              //bttnLink={btnLink}
              //btnText={btnText}
            />
            <a href="https://docs.google.com/forms/d/e/1FAIpQLSc01Pe2a6sl2GAnGtNIZ02CBoIy5Jb1M1R6uVlJjaNqpO5Wzg/viewform?usp=sf_link">   Click here to join the club!</a>
          </>
          
        )}
        <a href="https://docs.google.com/forms/d/e/1FAIpQLSc01Pe2a6sl2GAnGtNIZ02CBoIy5Jb1M1R6uVlJjaNqpO5Wzg/viewform?usp=sf_link">   Click here to join the club!</a>
      </Div>
    </Div>
  )
}
